import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Curriculum from "./pages/curriculum";
import Home from "./pages/home";
import Error from "./pages/error";
// import Sidewalkadvocates from "./pages/sidewalkadvocates";
import EmergencyResources from "./pages/emergency-resources";
import PregnancyServices from "./pages/pregnancy-services";
import AfterAbortionCare from "./pages/after-abortion-care";
import OrderCurriculum from "./pages/order-curriculum";
import CommunityOutreach from "./pages/community-outreach";

import About from "./pages/about";
import Contact from "./pages/contact";
import GetInvolved from "./pages/get-involved";
// import Testimonies from "./pages/testimonies";
// import Newsletter from "./pages/newsletter";
// import "./App.css";

import ReactGA from "react-ga";
import { useEffect } from "react";
const TRACKING_ID = "UA-258749121-1";
ReactGA.initialize(TRACKING_ID);

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        {/* <Route path="/testimonies" element={<Testimonies />} /> */}
        <Route path="/contact" element={<Contact />} />
        {/* <Route path="/newsletter" element={<Newsletter />} /> */}
        <Route path="/get-involved" element={<GetInvolved />} />
        <Route path="/curriculum" element={<Curriculum />} />
        <Route path="/curriculum/order" element={<OrderCurriculum />} />
        {/* <Route path="/sidewalk-advocates" element={<Sidewalkadvocates />} /> */}
        <Route path="/emergency-resources" element={<EmergencyResources />} />
        <Route path="/pregnancy-services" element={<PregnancyServices />} />
        <Route path="/after-abortion-care" element={<AfterAbortionCare />} />
        <Route path="/community-outreach" element={<CommunityOutreach />} />

        <Route path="*" element={<Error />} />
      </Routes>
    </Router>
  );
}

export default App;
