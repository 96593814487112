import React from "react";
import logo from "../assets/pathway_rect.webp";
import { SiFacebook } from "react-icons/si";

export default function Nav() {
  const handleHamburgerClick = () => {
    const btn = document.getElementById("menu-btn");
    // const nav = document.getElementById("menu");
    const mobileMenuWrapper = document.getElementById("mobile-menu-wrapper");

    btn.classList.toggle("open");
    // nav.classList.toggle("hidden");
    mobileMenuWrapper.classList.toggle("mobile-menu-open");
    mobileMenuWrapper.classList.toggle("mobile-menu-close");
  };

  return (
    <div className="fixed top-0 z-30 w-full bg-white font-rubik shadow-md">
      <nav className="container relative mx-auto p-4">
        <div className="flex items-center justify-between">
          <div className="pt-2">
            <a href="/">
              <img src={logo} alt="" className="h-[50px]" loading="lazy" />
            </a>
          </div>
          {/* Hamburger */}
          <button
            id="menu-btn"
            className="hamburger mr-2 mt-2 block focus:outline-none md:hidden"
            onClick={handleHamburgerClick}
          >
            <span className="hamburger-top"></span>
            <span className="hamburger-middle"></span>
            <span className="hamburger-bottom"></span>
          </button>
          {/* Menu items */}
          <div className="hidden items-center gap-5 md:flex">
            <div className="group relative cursor-pointer">
              <p>Services</p>
              <div className="z-80 fixed hidden  flex-col bg-white drop-shadow-lg hover:flex group-hover:flex">
                <a
                  className="px-5 py-3 text-sm hover:bg-gray-200"
                  href="/pregnancy-services"
                >
                  Pregnancy Services
                </a>

                <a
                  className="px-5 py-3 text-sm hover:bg-gray-200"
                  href="/emergency-resources"
                >
                  Emergency Resources
                </a>
                <a
                  className="px-5 py-3 text-sm hover:bg-gray-200"
                  href="/after-abortion-care"
                >
                  After Abortion Care
                </a>
                <a
                  className="px-5 py-3 text-sm hover:bg-gray-200"
                  href="/curriculum"
                >
                  Youth Curriculum
                </a>
                {/* <a
                  className="px-5 py-3 text-sm hover:bg-gray-200"
                  href="/sidewalk-advocates"
                >
                  Sidewalk Advocates
                </a> */}
              </div>
            </div>
            <a href="/about">About</a>
            {/* <a href="/testimonies">Stories</a> */}
            <div className="group relative cursor-pointer">
              <p>Contact</p>
              <div className="fixed z-40 hidden flex-col bg-white drop-shadow-lg hover:flex group-hover:flex">
                <a
                  className="px-5 py-3 text-sm hover:bg-gray-200"
                  href="/contact"
                >
                  Get In Touch
                </a>
                {/* <a
                  className="px-5 py-3 text-sm hover:bg-gray-200"
                  href="/newsletter"
                >
                  Newsletter
                </a> */}
                <a
                  className="px-5 py-3 text-sm hover:bg-gray-200"
                  href="/contact"
                >
                  Volunteer
                </a>
              </div>
            </div>
            <a
              href="https://engage.suran.com/pathwayresourcecenter/s/give/gift/80c96cabadbd4f9"
              target="_blank"
              rel="noreferrer"
              className="rounded-md bg-[#457B9D] px-2 py-1 text-white"
            >
              Donate
            </a>
            <a
              href="https://www.facebook.com/PathwayTXK/"
              target="_blank"
              rel="noreferrer"
              className="rounded-md px-1 py-1 text-3xl text-[#457B9D]"
            >
              <SiFacebook />
            </a>
          </div>
        </div>
        {/* Mobile Menu */}
        <div
          className="mobile-menu-close w-screen md:hidden"
          id="mobile-menu-wrapper"
        >
          <div
            id="menu"
            className="fixed left-[-16px] mt-4 mr-2 flex w-screen flex-col gap-4 rounded-b-md bg-gray-600 py-6 px-3 text-center font-bold text-white"
          >
            <a
              href="https://engage.suran.com/pathwayresourcecenter/s/give/gift/80c96cabadbd4f9"
              target="_blank"
              rel="noreferrer"
              className="m-auto  w-24 rounded-md bg-[#457B9D] px-2 py-1 text-center text-white"
            >
              Donate
            </a>
            <h1 className="m-auto w-1/2 border-b">Services</h1>
            <a href="/pregnancy-services" className="text-sm font-normal">
              Pregnancy Services
            </a>
            <a href="/emergency-resources" className="text-sm font-normal">
              Emergency Resources
            </a>
            <a href="/after-abortion-care" className="text-sm font-normal">
              After Abortion Care
            </a>

            <a href="/curriculum" className="text-sm font-normal">
              Youth Curriculum
            </a>

            {/* <a href="/sidewalk-advocates" className="font-normal text-sm">
                Sidewalk Advocates
              </a> */}
            <h1 className="m-auto w-1/2 border-b">About</h1>
            <a href="/about" className="text-sm font-normal">
              Who We Are
            </a>
            {/* <a href="/testimonies" className="font-normal text-sm">
              Stories
            </a> */}
            <h1 className="m-auto w-1/2 border-b">Contact</h1>
            <a href="/contact" className="text-sm font-normal">
              Get In Touch
            </a>
            {/* <a href="/newsletter" className="text-sm font-normal">
              Newsletter
            </a> */}
            <a href="/contact" className="text-sm font-normal">
              Volunteer
            </a>
            <a
              href="https://www.facebook.com/PathwayTXK/"
              target="_blank"
              rel="noreferrer"
              className="mx-auto rounded-full bg-white text-4xl text-[#457B9D]"
            >
              <SiFacebook />
            </a>
          </div>
        </div>
      </nav>
    </div>
  );
}
