import React from "react";
import pathway_with_contact_rect from "../assets/pathway_with_contact_rect.webp";

export default function Footer() {
  return (
    <div>
      {" "}
      {/* Footer */}
      <footer className=" bg-gray-700">
        <div className="mx-auto mb-12 flex flex-col-reverse justify-center gap-12 px-6 py-10 md:mb-0 md:flex-row">
          {/* logo and social links container */}
          <div className="flex flex-col-reverse items-center justify-between space-y-12 md:flex-col lg:my-auto">
            <img
              src={pathway_with_contact_rect}
              alt=""
              className=" w-48 rounded-md"
              loading="lazy"
            />
          </div>
          <div className="my-auto lg:w-[40%]">
            <div className="text-center text-white">
              We provide our services without discrimination to people of any
              age, race, income, nationality, religious affiliation, disability,
              or other arbitrary circumstances. Because we are pro-woman and a
              non-profit 501 (c) 3, we do not provide or refer for abortion
              services. We provide all of our services at no cost.
            </div>
          </div>
          {/* <div
            className="ml-form-embed embeddedForm"
            data-account="3392599:g3f2s7l6c2"
            data-form="5825078:n6n6j1"
          ></div> */}
        </div>
      </footer>
    </div>
  );
}
